$primary:#232253;
$secondary:#EE5557;
$creme: #F1EDE6;
$black: #1A1A1A;
$cyan:#CDE5F6;
$darkGray: #C9C0B1;
$blue:#084E8E;
$purple:#6561A8;
$pink:#E67FA3;
$lightBlue:#67B4E2;
$emerald: #5AC5CD;
$orange:#E47170;
$darkPurple:#8D5889;
$gradientPrimary:#141b4d;
$gradientSecondary:#E03E52;
// nouvelle couleur ARCEP
$redArcepNav:#E03E52;
$blueArcepNav:#141b4d;

@font-face {
  font-family: 'SansOpen';
  src: url('OpenSans.ttf');
}

body {
  font-family: 'SansOpen' !important;
  color: #000!important;
}

.text-blue-color-arcep{
  color: #084E8E;
}


.title-header-blue-color-arcep{
  color: #232253;
}


:root {
  --col-grid: 1;
  --animation-duration: 200ms;
  --animation-timing-curve: ease-in-out;
  --blue: $blue;
  --indigo: #6610f2;
  --purple: $purple;
  --pink: $pink;
  --red: $secondary;
  --orange: #fd7e14;
  --yellow: #f6c23e;
  --green: #1cc88a;
  --teal: $emerald;
  --cyan: $cyan;
  --white: #fff;
  --gray: #858796;
  --gray-dark: $darkGray;
  --primary: $primary;
  --secondary: #858796;
  --success: #1cc88a;
  --info: $lightBlue;
  --warning: #f6c23e;
  --danger: $secondary;
  --light: #f8f9fc;
  --dark: #5a5c69;
}
