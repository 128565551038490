@import "variables";

.h {
  &-6 {
    height: 3rem;
  }

  &-8 {
    height: 4rem;
  }
}

.w {

  &-6 {
    width: 3rem;
  }

  &-14 {
    width: 14rem;
  }
}

.underline {
  text-decoration: underline;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.cursor-pointer {
  cursor: pointer;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.block {
  display: block;
}

.hover\:underline:hover {
  text-decoration: underline;
}

.rotate-180 {
  transform: rotateX(180deg);
}

.pe-none {
  pointer-events: none;
}

.link-table {
  & > svg {
    min-height: 1.5rem;
    min-width: 1.5rem;
    fill: black;
  }
}

a {
  color: $blue;
}

.matrix {
  &-container {
    display: flex;
    flex-direction: column;
    width: auto;
    border: 1px solid black;
    margin-right: auto;
    margin-left: auto;
    max-width: 75vw;
  }

  &-row {
    display: flex;
    flex-direction: row;
    max-height: 2rem;
    min-height: 2rem;
    &:not(:last-child) {
      border-bottom: 1px solid #979797;
    }

  }

  &-col {
    width: 6rem;
    color: revert;
    overflow-x: scroll;
    padding: 0;
    margin: 0;

    &::-webkit-scrollbar-thumb{
      width: 0;
      margin: 0;
      padding: 0;
    }
    &::-webkit-scrollbar {
      display: none;
    }

    &:not(:last-child) {
      border-right: 1px solid #979797;
    }
  }
}


.list-indicateur {

  ul {
    max-height: 25vh;
    overflow-y: scroll;
    display: grid;
    justify-content: center;
    justify-items: stretch;
    align-content: center;
    grid-template-columns: repeat(auto-fit, minmax(6rem, 8rem));
    column-gap: 1rem;
    row-gap: 1rem;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0;

    li {
      align-self: center;
      justify-items: center;
      align-items: center;
      text-align: center;
      border: $black 1px solid;
      background-color: $creme;
      border-radius: 15px;
      padding: 0.5rem 1rem;
      text-decoration: none;
      list-style: none;

      &.selected {
        background-color: $lightBlue;
      }
    }
  }
}

.switch {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 34px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $creme;
    -webkit-transition: .4s;
    transition: .4s;
    width: 4rem;

    &::before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }
  }

  input:checked + .slider {
    background-color: $primary;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px $primary;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .round {
    border-radius: 34px;

    &:before {
      border-radius: 50%;
    }
  }
}

.text-primary {
  color: $primary;
}

.sidebar {
  background: linear-gradient(320deg, $redArcepNav 10%, $blueArcepNav 60%);
  transition: width var(--animation-duration) var(--animation-timing-curve);

  .nav-item {
    position: relative;

    i {
      fill: $primary;
      color: $primary;
    }

    &.active {
      //background-color: $orange;

      &::before {
        content: "";
        background-color: $creme;
        height: 100%;
        position: absolute;
        left: 0;
        width: 0.5rem;
      }
    }
  }

  .sidebar-brand-icon {
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;

    #logo-large {
      display: block;
    }

    #logo-small {
      display: none;
    }
  }


  &.toggled {
    #logo-large {
      display: none;
    }

    #logo-small {
      display: block;
    }
  }
}

.next, .previous {
  .page-link {
    background-color: white;
    color: #858796;
  }
}

.page-item.active {
  .page-link {
    background-color: $blue;
    border-color: $blue;
  }
}

.page-link {
  background-color: $primary;
  color: $creme;

  &:hover {
    background-color: $blue;
    color: $creme;
  }
}

.card-body {
  color: $black;
}

.btn-outline {
  &-danger {
    border-color: $secondary;

    &:hover {
      background-color: $secondary;
    }
  }

  &-success {
    border-color: $emerald;
    color: $emerald;

    &:hover {
      background-color: $emerald;
      color: white;
    }
  }

  &-primary {
    border-color: $primary;
    color: $black;

    &:hover {
      color: white;
      background-color: $primary;
    }
  }

}


.btn {
  transition: background-color 0.75s ease;

  &-danger {
    background-color: $secondary;

    &:hover {
      filter: brightness(1.2)
    }
  }

  &-success {
    background-color: $emerald;

    &:hover {
      filter: brightness(1.2);
      color: white;
    }
  }

  &-primary {
    color: white;
    background-color: $primary;
    &:hover {
      color: white;
      filter: brightness(0.9);
    }
  }

}

@media (min-width: 640px) {
  :root {
    --col-grid: 1;
  }

  .sidebar .sidebar-brand-icon {
    #logo-large {
      display: none;
    }

    #logo-small {
      display: block;
    }
  }
}

@media (min-width: 768px) {
  .sidebar .sidebar-brand-icon {
    #logo-large {
      display: block;
    }

    #logo-small {
      display: none;
    }
  }
  :root {
    --col-grid: 2;
  }

  .list-indicateur {
    overflow-y: initial;

    ul {
      max-height: 75vh;
      overflow-y: auto;
    }
  }
}

@media (min-width: 1024px) {
  :root {
    --col-grid: 2;
  }

}

@media (min-width: 1280px) {

  :root {
    --col-grid: 3;
  }
}

@media (min-width: 1536px) {

  :root {
    --col-grid: 4;
  }
}

